import EditIcon from "@mui/icons-material/EditOutlined";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { GridColDef, GridGroupingColDefOverride } from "@mui/x-data-grid-premium";
import { convertISODate } from "../../../../../../shared/utilities/dateUtils";
import { FundraisingAccessItem } from "../../../../../api/types/fundraisingTypes";
import GroupHeader from "../../../../common/grid/GroupHeader";
import PortalRoleChip from "../../../../common/PortalRoleChip";
import { useFundraisingAccessGridContext } from "./FundraisingAccessGridContext";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";

export const applySearchFilter = (searchValue: string, allRows: FundraisingAccessItem[]) => {
  const searchTerm = searchValue.toLowerCase().trim();
  if (searchTerm.length === 0) {
    return allRows;
  }

  return allRows.filter(
    (row) =>
      row.investorName.toLowerCase().includes(searchTerm) ||
      row.contactName.toLowerCase().includes(searchTerm) ||
      (row.contactEmail ?? "").toLowerCase().includes(searchTerm)
  );
};

export const groupingColumnDefinition: GridGroupingColDefOverride<FundraisingAccessItem> = {
  leafField: "contactName",
  renderHeader: (params) => <GroupHeader params={params} />,
  flex: 1,
};

const CategoriesCell = ({ row }: { row: FundraisingAccessItem }) => {
  const { categories } = useFundraisingDetailsPageContext();

  const { categoryIds } = row;
  const text = categoryIds.length === categories.length ? "All" : `${categoryIds.length}/${categories.length}`;

  return (
    <Tooltip
      arrow
      title={
        <Stack>
          {categories.map((c) =>
            categoryIds.includes(c.externalId) ? (
              <Box key={c.externalId} sx={{ whiteSpace: "pre-line" }}>
                {c.name}
              </Box>
            ) : (
              <Box
                key={c.externalId}
                sx={{ whiteSpace: "pre-line", textDecorationLine: "line-through", color: grey[500] }}
              >
                {c.name}
              </Box>
            )
          )}
        </Stack>
      }
    >
      <Typography>{text}</Typography>
    </Tooltip>
  );
};

const ActionsCell = ({ row }: { row: FundraisingAccessItem }) => {
  const { isContentEditable } = useFundraisingDetailsPageContext();
  const { onEditAccess } = useFundraisingAccessGridContext();

  if (!isContentEditable) {
    return null;
  }

  return (
    <Stack direction="row" width="100%" spacing={1}>
      <IconButton size="small" onClick={() => onEditAccess(row)}>
        <EditIcon />
      </IconButton>
    </Stack>
  );
};

export const columnDefinitions: GridColDef<FundraisingAccessItem>[] = [
  {
    field: "investorName",
    headerName: "",
    renderCell: ({ rowNode, field, value }) => {
      if (rowNode.type === "group" && field === rowNode.groupingField) {
        return "";
      }

      return (
        <Typography variant="subtitle2" noWrap>
          {value}
        </Typography>
      );
    },
  },
  {
    field: "contactName",
    headerName: "Name",
    renderCell: ({ row, rowNode }) => {
      if (rowNode.type === "group") {
        return "";
      }

      return (
        <Stack spacing={0.5} width="100%" pl={5}>
          <Typography variant="subtitle2" noWrap>
            {row.contactName}
          </Typography>
          <Typography variant="caption" color="text.secondary" noWrap>
            {row.contactEmail}
          </Typography>
        </Stack>
      );
    },
  },
  {
    field: "roles",
    headerName: "Role",
    minWidth: 160,
    renderCell: ({ row, rowNode }) => {
      if (rowNode.type === "group") {
        return "";
      }

      return <PortalRoleChip roles={row.roles} />;
    },
  },
  {
    field: "categories",
    headerName: "Access Categories",
    minWidth: 160,
    renderCell: ({ row, rowNode }) => {
      if (rowNode.type === "group") {
        return "";
      }

      return <CategoriesCell row={row} />;
    },
  },
  {
    field: "registrationDate",
    headerName: "Registration Status",
    width: 160,
    renderCell: ({ row, rowNode }) => {
      if (rowNode.type === "group") {
        return "";
      }

      return row.registrationDate ? (
        <Stack spacing={0.5} width="100%">
          <Typography>Registered</Typography>
          <Typography variant="caption" color="text.secondary" noWrap>
            {convertISODate(row.registrationDate)}
          </Typography>
        </Stack>
      ) : (
        <Typography>Not Registered</Typography>
      );
    },
  },
  {
    field: "actions",
    headerName: "",
    width: 50,
    align: "right",
    cellClassName: "grid-row-actions",
    renderCell: ({ row, rowNode }) => (rowNode.type === "group" ? null : <ActionsCell row={row} />),
  },
];
