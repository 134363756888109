import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SaveIcon from "@mui/icons-material/FileDownloadOutlined";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import { logError } from "../../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../../shared/services/downloadFile";
import { convertISODate } from "../../../../../shared/utilities/dateUtils";
import adminApi, { DocumentCollectionDetails } from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import DocumentCollectionStatusTag from "../DocumentCollectionStatusTag";
import DeliveryProgress from "./DeliveryProgress";

interface Props {
  documentCollectionDetails: DocumentCollectionDetails;
}

const getDownloadUrlForDocumentCollection = withErrorHandling(adminApi.getDownloadUrlForDocumentCollection);

const DocumentCollectionHeader = ({ documentCollectionDetails }: Props) => {
  const navigate = useNavigate();
  const { clientCode } = useClientContext();

  const handleBackClick = () => {
    navigate(`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.documentCollections}`, {
      state: { documentCollectionId: documentCollectionDetails.id },
    });
  };

  const handleDownloadAllClick = async () => {
    const [url, error] = await getDownloadUrlForDocumentCollection(documentCollectionDetails.id, clientCode);
    if (error) {
      logError(error, "[DocumentCollectionHeader] handleDownloadAllClick");
      return;
    }

    downloadFileFromUrl(url);
  };

  return (
    <Box display="flex" justifyContent="space-between">
      <Box ml={1} display="flex" alignItems="flex-start" gap={2}>
        <IconButton onClick={handleBackClick}>
          <ArrowBackRoundedIcon />
        </IconButton>
        <Stack spacing={1}>
          <Box display="flex" gap={2}>
            <Typography variant="h6">{documentCollectionDetails.name}</Typography>
            <DocumentCollectionStatusTag status={documentCollectionDetails.status} />
          </Box>
          <Box display="flex" gap={2}>
            <Typography color="text.secondary">{documentCollectionDetails.fundName}</Typography>
            <Typography color="text.secondary">{`${documentCollectionDetails.messageLevel} level`}</Typography>
            <Typography color="text.secondary">{convertISODate(documentCollectionDetails.publishDate)}</Typography>
          </Box>
        </Stack>
      </Box>
      <Box display="flex" alignItems="center">
        <Box pr={2}>
          <DeliveryProgress
            documentsCount={documentCollectionDetails.documents.length}
            deliveryProgress={documentCollectionDetails.deliveryProgress}
          />
        </Box>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleDownloadAllClick}
          startIcon={<SaveIcon />}
          sx={{ ml: 2 }}
        >
          All Documents
        </Button>
      </Box>
    </Box>
  );
};

export default DocumentCollectionHeader;
