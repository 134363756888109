import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import { arraysHaveSameItems } from "../../../../../../shared/utilities/arrayHelper";
import adminApi from "../../../../../api/adminApi";
import { PortalRole } from "../../../../../api/types/accessTypes";
import { FundraisingAccessItem } from "../../../../../api/types/fundraisingTypes";
import PortalRoleSelector from "../../../../common/PortalRoleSelector";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";

interface Props {
  fundId: string;
  editedAccessItem: FundraisingAccessItem;
  onSubmit: () => void;
  onClose: () => void;
}

interface FormState {
  roles: PortalRole[];
  selectedCategoryIds: string[];
}

const getInitialFormState = (editedAccessItem: FundraisingAccessItem): FormState => ({
  roles: editedAccessItem.roles,
  selectedCategoryIds: editedAccessItem.categoryIds,
});

const updateFundCommunicationMatrix = withErrorHandling(adminApi.updateFundCommunicationMatrix);

const EditFundraisingAccessDialog = ({ fundId, editedAccessItem, onSubmit, onClose }: Props) => {
  const { sendNotificationError } = useNotificationContext();
  const { categories } = useFundraisingDetailsPageContext();
  const [formState, setFormState] = useState<FormState>(getInitialFormState(editedAccessItem));
  const [isSaving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);

    const [, error] = await updateFundCommunicationMatrix(fundId, {
      updates: [
        {
          fundInvestorId: editedAccessItem.fundInvestorId,
          contactId: editedAccessItem.contactId,
          roles: formState.roles,
          categories: formState.selectedCategoryIds,
        },
      ],
      removes: [],
      creates: [],
    });

    setSaving(false);

    if (error) {
      logError(error, "[EditFundraisingAccessDialog] updateFundCommunicationMatrix");
      sendNotificationError("Failed to update access");
      return;
    }

    onClose();
    onSubmit();
  };

  const handleRolesChange = (roles: PortalRole[]) => {
    setFormState((prev) => ({ ...prev, roles }));
  };

  const handleAllCategoriesChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFormState((prev) => ({ ...prev, selectedCategoryIds: checked ? categories.map((c) => c.externalId) : [] }));
  };

  const handleCategoryChange = (categoryId: string) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFormState((prev) => ({
      ...prev,
      selectedCategoryIds: checked
        ? [...prev.selectedCategoryIds, categoryId]
        : prev.selectedCategoryIds.filter((id) => id !== categoryId),
    }));
  };

  const isDirty =
    !arraysHaveSameItems(editedAccessItem.roles, formState.roles) ||
    !arraysHaveSameItems(editedAccessItem.categoryIds, formState.selectedCategoryIds);

  const areAllCategoriesSelected = formState.selectedCategoryIds.length === categories.length;
  const isAnyCategorySelected = formState.selectedCategoryIds.length > 0;

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit {editedAccessItem.contactName}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <Divider />

      <DialogContent>
        <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
          Access
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>Role</Typography>
          <PortalRoleSelector roles={formState.roles} onChange={handleRolesChange} />
        </Box>
        <Divider />
        <Stack spacing={0.5} pt={0.5}>
          {categories.length > 1 && (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography>All Categories</Typography>
              <Checkbox
                checked={areAllCategoriesSelected}
                indeterminate={!areAllCategoriesSelected && isAnyCategorySelected}
                onChange={handleAllCategoriesChange}
              />
            </Box>
          )}
          {categories.map((category) => (
            <Box key={category.externalId} display="flex" justifyContent="space-between" alignItems="center">
              <Typography>{category.name}</Typography>
              <Checkbox
                checked={formState.selectedCategoryIds.includes(category.externalId)}
                onChange={handleCategoryChange(category.externalId)}
              />
            </Box>
          ))}
        </Stack>
      </DialogContent>

      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" loading={isSaving} onClick={handleSave} disabled={!isDirty}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditFundraisingAccessDialog;
