import { Alert, Stack } from "@mui/material";
import PageDeactivatedAlert from "../PageDeactivatedAlert";
import FundraisingConfigurationSection from "./FundraisingConfigurationSection";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";
import FundraisingDetailsSection from "./FundraisingDetailsSection";

const FundraisingOverview = () => {
  const { fundraising, isPageDeactivatedOnPortal } = useFundraisingDetailsPageContext();

  return (
    <Stack spacing={2.5} flex={1}>
      {isPageDeactivatedOnPortal && <PageDeactivatedAlert />}
      {fundraising.status === "Preview" && (
        <Alert severity="info">
          This fundraising is in Preview. Publish to Live to start sharing information and collecting document activity.
        </Alert>
      )}
      <FundraisingDetailsSection />
      <FundraisingConfigurationSection />
    </Stack>
  );
};

export default FundraisingOverview;
