import { FundraisingStatus } from "../../shared/api/fundraisingTypes";
import { EntriliaProduct } from "../../shared/api/types";
import { PortalRole } from "../api/types/accessTypes";
import { EntityFieldType, EntityOptionFieldType, ObjectFeature } from "../api/types/objectTypes";

export const entriliaProductCaptionMap: Record<EntriliaProduct, string> = {
  EntriliaPlatform: "Entrilia Platform",
  OrganizationManagement: "Organization Management",
  CompanyManagement: "Settings",
  FundAccounting: "Fund Operations",
  InvestorPortal: "Investor Portal",
  ReportingPortal: "SmartViews",
  AdminPortal: "Admin Portal",
  InvestorRelationsManagement: "Investor Relations",
  DataRoom: "Data Room",
  ExpenseManagement: "Expense Management",
  PortfolioMonitoring: "Portfolio Monitoring",
  WorkflowManagement: "Workflow Management",
  DwhManagement: "Data Warehouse",
  BackupsManagement: "Backups",
  ApiGateway: "API Gateway",
};

export const objectFeatureCaptionMap: Record<ObjectFeature, string> = {
  Notes: "Notes",
  Files: "Files",
  TimeSeriesFields: "Time Series",
  DataImport: "Data Import",
};

export const fieldTypeCaptionMap: Record<EntityFieldType, string> = {
  Text: "Text",
  Email: "Email",
  Phone: "Phone",
  Url: "URL",
  Number: "Number",
  Money: "Money",
  Percent: "Percent",
  Select: "Select",
  MultiSelect: "Multi-Select",
  UserDefinedOptionsSelect: "Select",
  UserDefinedOptionsMultiSelect: "Multi-Select",
  Date: "Date",
  Checkbox: "Checkbox",
  Lookup: "Lookup",
};

export const entityOptionFieldTypeCaptionMap: Record<EntityOptionFieldType, string> = {
  Country: "Countries",
  State: "States",
  Currency: "Currencies",
  TaxForm: "Tax Forms",
  InvestorCategory: "Investor Categories",
  FormPfName: "Form PF Names",
  Chapter3Status: "Chapter 3 Statuses",
  Chapter4FatcaStatus: "Chapter 4 Fatca Statuses",
  Irs1099Code: "IRS 1099 Codes",
  IndustryOrSector: "Industries / Sectors",
  Geography: "Geographies",
};

export const fundraisingStatusCaptionMap: Record<FundraisingStatus, string> = {
  Draft: "Draft",
  Preview: "Preview",
  Live: "Live",
  Completed: "Completed",
};

export const portalRoleCaptionMap: Record<PortalRole, string> = {
  LP_Admin: "Admin",
  LP_Member: "Member",
};
