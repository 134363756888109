import { Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import CenteredWrapper from "../../../../../../shared/components/CenteredWrapper";
import TypographyTooltipEllipsis from "../../../../../../shared/components/TypographyTooltipEllipsis";
import {
  communicationGroupNameField,
  dashboardBaseBalanceFields,
} from "../../../../../../shared/components/balances/balanceFieldset";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { logError } from "../../../../../../shared/logging";
import { TabularDataCell } from "../../../../../../shared/reporting/api/biClient.types";
import { convertISODateShort } from "../../../../../../shared/utilities/dateUtils";
import { InvestorBalanceResponse } from "../../../../../api/adminApi";

interface InvestorBalanceTableProps {
  balanceResponse: InvestorBalanceResponse | undefined;
  isLoading: boolean;
}

type TabularCell = {
  id: string;
  fields: { [key: string]: TabularDataCell };
  isTotal?: boolean;
};

const InvestorBalanceTable = ({ balanceResponse, isLoading }: InvestorBalanceTableProps) => {
  const loading = isLoading || !balanceResponse;
  const reportingDates = balanceResponse?.fundReportingDates;
  const balanceData = balanceResponse?.balanceData || [];

  const rows: TabularCell[] = balanceData.map((data, index) => {
    return {
      id: index.toString(),
      fields: data,
    };
  });

  if (rows.length > 1) {
    const totals: { [key: string]: TabularDataCell } = {};
    rows.forEach((row) => {
      dashboardBaseBalanceFields.forEach((field) => {
        const value = row.fields[field.guid]?.value;
        if (value && field.config?.calculateTotal) {
          const numValue = +value;
          if (isNaN(numValue)) {
            logError(
              `Can't calculate total for non number value ${value}. FieldId: ${field.guid}`,
              "[InvestorBalanceTable]"
            );
          }
          const cellTotal = totals[field.guid];
          if (cellTotal && typeof cellTotal.value === "number") {
            cellTotal.value += numValue || 0;
          } else {
            totals[field.guid] = {
              value: numValue,
              formattedValue: "",
            };
          }
        }
      });
    });
    rows.push({
      id: "total",
      fields: totals,
      isTotal: true,
    });
  }

  const columns: GridColDef<TabularCell>[] = [
    {
      field: communicationGroupNameField.guid,
      headerName: "Name",
      flex: 1.5,
      minWidth: 100,
      sortable: false,
      disableReorder: true,
      align: communicationGroupNameField.config.align,
      headerAlign: communicationGroupNameField.config.align,
      renderCell: ({ row }) => {
        const rowValue = row.fields[communicationGroupNameField.guid]?.value;
        if (!rowValue) {
          return null;
        }
        const value = rowValue + "";
        const valueFormatted = communicationGroupNameField.config?.format
          ? communicationGroupNameField.config.format(value)
          : value;
        const reportingDate = reportingDates?.[value];
        return (
          <Stack>
            <TypographyTooltipEllipsis
              text={valueFormatted ?? row.fields[communicationGroupNameField.guid]?.formattedValue}
            />
            {reportingDate && (
              <Typography color="text.secondary" variant="caption">{`as of ${convertISODateShort(
                reportingDate
              )}`}</Typography>
            )}
          </Stack>
        );
      },
    },
  ];

  const balanceColumns: GridColDef<TabularCell>[] = dashboardBaseBalanceFields.map((field) => {
    const config = field.config;
    return {
      field: field.guid,
      headerName: config.title,
      flex: 1,
      minWidth: 100,
      sortable: false,
      disableReorder: true,
      align: config.align,
      headerAlign: config.align,
      resizable: false,
      availableAggregationFunctions: ["sum"],
      renderCell: ({ row }) => {
        const value = row.fields[field.guid]?.value;
        const valueFormatted = config?.format ? config.format(value + "") : value + "";
        if (row.isTotal && !value) {
          return null;
        }
        return (
          <Typography fontWeight={row.isTotal ? 500 : 400}>
            {valueFormatted ?? row.fields[field.guid]?.formattedValue}
          </Typography>
        );
      },
    };
  });

  columns.push(...balanceColumns);

  return (
    <DataGrid<TabularCell>
      getRowId={(row) => row.id}
      slots={{
        loadingOverlay: () => <InlineLoader />,
        noRowsOverlay: () => (
          <CenteredWrapper>
            <Typography color="textSecondary">No data</Typography>
          </CenteredWrapper>
        ),
      }}
      columns={columns}
      rows={rows}
      loading={loading}
      rowHeight={52}
      disableRowSelectionOnClick
      hideFooter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
    />
  );
};

export default InvestorBalanceTable;
