import StatusChip from "../../../../shared/components/StatusChip";
import { DocumentCollectionPublishStatus } from "../../../api/adminApi";

interface Props {
  status: DocumentCollectionPublishStatus;
}

const DocumentCollectionStatusTag = ({ status }: Props) => {
  switch (status) {
    case "Live":
      return <StatusChip label="Live" withDot color={(t) => t.palette.success.main} />;
    case "PartiallyLive":
      return <StatusChip label="Live (Partially)" withDot color={(t) => t.palette.success.main} />;
    case "Preview":
      return <StatusChip label="Preview" withDot color={(t) => t.palette.info.main} />;
    default:
      return null;
  }
};

export default DocumentCollectionStatusTag;
