import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FundDetails, FundPermissionsResponse } from "../../../../api/adminApi";
import { Category } from "../../../../api/types/accessTypes";
import { ObjectClassDefinition, ObjectPermissions } from "../../../../api/types/objectTypes";
import { useClientContext } from "../../../../context/ClientContext";
import EntityTitle from "../../../common/EntityTitle";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import FullHeightFlexPage from "../../../common/layout/FullHeightFlexPage";
import StretchedScrollableContent from "../../../common/layout/StretchedScrollableContent";
import { getDataCatalogueEntityType } from "../../../entityFields/entityTypesHelper";
import EntityCardFiles from "../../../entityFields/files/EntityCardFiles";
import FundCommunicationMatrixTab from "./communication-matrix/FundCommunicationMatrixTab";
import FundDocumentsTab from "./documents/FundDocumentsTab";
import FundMainInfoTab from "./main/FundMainInfoTab";

interface Props {
  fundDetails: FundDetails;
  accessCategories: Category[];
  fundPermissions: FundPermissionsResponse;
  objectDefinition: ObjectClassDefinition;
  objectPermissions: ObjectPermissions;
  onFundDetailsChange: (details: Partial<FundDetails>) => void;
}

const tabPanelSx = { height: "100%", m: 0, px: 3, pt: 2.5, pb: 0 };

const FundDetailsPage = ({
  fundDetails,
  fundPermissions,
  accessCategories,
  objectDefinition,
  objectPermissions,
  onFundDetailsChange,
}: Props) => {
  const { tab } = useParams();
  const { hasAnyPermission, settings } = useClientContext();
  const [selectedTab, setSelectedTab] = useState(tab || "main");

  const hasPermissionsToViewFiles =
    hasAnyPermission(objectPermissions.filesReadPermissions) && settings.featureToggles.entityFilesEnabled;

  const hasPermissionsToManageFiles =
    hasAnyPermission(objectPermissions.filesWritePermissions) && settings.featureToggles.entityFilesEnabled;

  const { id, name } = fundDetails;

  const onTabChange = (newTab: string) => {
    setSelectedTab(newTab);
    window.history.replaceState({}, document.title, window.location.pathname.replace(/\/[^/]+$/, `/${newTab}`));
  };

  return (
    <TabContext value={selectedTab}>
      <FullHeightFlexPage>
        <Box>
          <GeneralPageHeader
            TitleComponent={<EntityTitle title={name} typeName={objectDefinition.objectName} />}
            hideBorderLine
            showDefaultBackButtonTo=".."
          />
          <TabList onChange={(_, tab) => onTabChange(tab)}>
            <Tab sx={{ ml: 3 }} label="Main Info" value="main" />
            <Tab label="Investor Documents" value="documents" />
            {hasPermissionsToViewFiles && <Tab label="Files" value="files" />}
            <Tab label="Communication Matrix" value="communication-matrix" />
          </TabList>
        </Box>
        <StretchedScrollableContent>
          <TabPanel value="main" sx={{ ...tabPanelSx, backgroundColor: "background.grey" }}>
            <FundMainInfoTab
              fundDetails={fundDetails}
              objectDefinition={objectDefinition}
              objectPermissions={objectPermissions}
              onFundDetailsChange={onFundDetailsChange}
            />
          </TabPanel>
          <TabPanel value="documents" sx={tabPanelSx}>
            <FundDocumentsTab fundId={id} categories={accessCategories} fundPermissions={fundPermissions.permissions} />
          </TabPanel>
          {hasPermissionsToViewFiles && (
            <TabPanel value="files" sx={{ height: "100%", m: 0, px: 0, py: 0 }}>
              <EntityCardFiles
                entityType={getDataCatalogueEntityType(objectDefinition.objectType)}
                entityId={id}
                entityName={name}
                hasPermissionsToManage={hasPermissionsToManageFiles}
              />
            </TabPanel>
          )}
          <TabPanel value="communication-matrix" sx={tabPanelSx}>
            <FundCommunicationMatrixTab categories={accessCategories} fundPermissions={fundPermissions} />
          </TabPanel>
        </StretchedScrollableContent>
      </FullHeightFlexPage>
    </TabContext>
  );
};

export default FundDetailsPage;
