import { LoadingButton } from "@mui/lab";
import { Button, Stack, Typography } from "@mui/material";
import deepEqual from "fast-deep-equal";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import adminApi from "../../../../../api/adminApi";
import { Fundraising } from "../../../../../api/types/fundraisingTypes";
import EmailTemplateEditor, { EmailTemplateSettings } from "../../../../common/email-templates/EmailTemplateEditor";
import { useFundraisingDetailsPageContext } from "./FundraisingDetailsPageContext";

const updateFundraising = withErrorHandling(adminApi.updateFundraising);

const snippets = [{ value: "{{RecipientName}}", displayName: "Recipient Name" }];

const defaultSubject = "You've been invited to access the Fundraising Data Room";
const getInitialSettings = (fundraising: Fundraising) => ({
  subject: fundraising.notificationEmailSettings?.subject ?? defaultSubject,
  body: fundraising.notificationEmailSettings?.body ?? "",
});

const FundraisingNotificationsConfig = () => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const { isContentEditable, fundraising, onUpdated } = useFundraisingDetailsPageContext();

  const [currentSettings, setCurrentSettings] = useState<EmailTemplateSettings>(getInitialSettings(fundraising));

  const [isSaving, setSaving] = useState(false);

  const handleSettingsChange = (update: Partial<EmailTemplateSettings>) =>
    setCurrentSettings((prev) => ({
      ...prev,
      ...update,
    }));

  const handleSave = async () => {
    setSaving(true);

    const [updatedFundraising, error] = await updateFundraising(fundraising.id, {
      notificationEmailSettings: currentSettings,
    });

    setSaving(false);

    if (error) {
      logError(error, "[FundraisingNotificationsConfig]");
      sendNotificationError("Could not update notification settings");
      return;
    }

    onUpdated(updatedFundraising);
    sendNotification("Notification settings updated successfully");
  };

  const handleReset = () => setCurrentSettings(getInitialSettings(fundraising));

  const isDirty = !deepEqual(getInitialSettings(fundraising), currentSettings);

  const validationErrors = {
    subject: isDirty && !currentSettings.subject.trim(),
    body: isDirty && !currentSettings.body.trim(),
  };

  const isValid = !validationErrors.subject && !validationErrors.body;

  return (
    <Stack spacing={3} flex={1}>
      <Stack spacing={0.5} pt={2}>
        <Typography variant="subtitle1">Notification</Typography>
        <Typography variant="caption" color="text.secondary">
          Send notification emails when the fundraising goes live.
        </Typography>
      </Stack>

      <Stack spacing={0.5} pt={2}>
        <Typography variant="subtitle1">Email</Typography>
        <EmailTemplateEditor
          snippets={snippets}
          settings={currentSettings}
          onChange={handleSettingsChange}
          disabled={!isContentEditable}
          validationErrors={validationErrors}
        />

        <Stack direction="row" spacing={1} pt={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={!isDirty || !isValid || !isContentEditable}
            loading={isSaving}
            onClick={handleSave}
          >
            Save
          </LoadingButton>
          <Button variant="text" color="secondary" disabled={!isDirty || !isContentEditable} onClick={handleReset}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FundraisingNotificationsConfig;
