import ObjectIcon from "@mui/icons-material/DataObjectRounded";
import { Box, Button, Popover, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import EmailSnippets, { EmailSnippet } from "./EmailSnippets";

interface Props {
  snippets: EmailSnippet[];
  settings: EmailTemplateSettings;
  onChange: (update: Partial<EmailTemplateSettings>) => void;
  disabled?: boolean;
  validationErrors?: Record<PropKey, boolean>;
}

export interface EmailTemplateSettings {
  subject: string;
  body: string;
}

type PropKey = keyof EmailTemplateSettings;

type InputSelection = [PropKey | undefined, number | null, number | null];

const EmailTemplateEditor = ({ snippets, settings, onChange, disabled, validationErrors }: Props) => {
  const [inputSelection, setInputSelection] = useState<InputSelection>([undefined, 0, 0]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const subjectInputRef = useRef<HTMLInputElement>();
  const bodyInputRef = useRef<HTMLInputElement>();

  const handleInputSelectionChange = (input: HTMLInputElement | undefined, propKey: PropKey) => {
    if (input === undefined) {
      return;
    }

    const { selectionStart, selectionEnd } = input;
    setInputSelection([propKey, selectionStart, selectionEnd]);
  };

  const handleSnippetSelected = (snippetValue: string) => {
    setAnchorEl(null);
    const [propName, start, end] = inputSelection;

    if (!propName || start === null || end === null) {
      return;
    }

    const text = settings[propName];
    const textStart = text.slice(0, start);
    const textEnd = text.slice(end);

    onChange({ ...settings, [propName]: textStart + snippetValue + textEnd });
  };

  return (
    <Box>
      <Typography color="text.secondary" mb={1}>
        You can add a Snippet to the Email Subject and Email Body. Select a field and add a Snippet.
      </Typography>
      <Button
        variant="outlined"
        color="secondary"
        disabled={disabled}
        startIcon={<ObjectIcon />}
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
      >
        Snippet
      </Button>
      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <EmailSnippets snippets={snippets} onSelected={handleSnippetSelected} />
      </Popover>
      <TextField
        spellCheck
        inputRef={subjectInputRef}
        fullWidth
        variant="outlined"
        label="Subject"
        disabled={disabled}
        value={settings.subject}
        error={validationErrors?.subject}
        onChange={({ target }) => onChange({ subject: target.value })}
        sx={(theme) => ({ mt: theme.spacing(4) })}
        onSelect={() => handleInputSelectionChange(subjectInputRef.current, "subject")}
      />
      <Typography variant="subtitle1" mt={2} mb={1}>
        Email Body
      </Typography>
      <TextField
        spellCheck
        inputRef={bodyInputRef}
        fullWidth
        multiline
        minRows={8}
        disabled={disabled}
        value={settings.body}
        error={validationErrors?.body}
        onChange={({ target }) => onChange({ body: target.value })}
        onSelect={() => handleInputSelectionChange(bodyInputRef.current, "body")}
      />
    </Box>
  );
};

export default EmailTemplateEditor;
